.Like {
    background-color: lightblue;
    padding: 5px 10px;
    border-radius: 10px;
    
}


.Comment {
    background-color: lightpink;
    padding: 5px 10px;
    border-radius: 10px;
    
}

.likeContainer {
    padding: 10px;
    width: max-content;
    display:inline-block;
}

#avatar{
    border-radius: 100%;
    width: 40px;
    height: 40px;
}

.chip{
    border-radius: 999999px;
    padding: 10px;
}
/* 
.filledChip{
        border-radius: 999999px;
        background-color: lightsteelblue;
        opacity: 0.5;
        position: absolute;
        top: 0;
} */